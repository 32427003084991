export default {
  async insert (Vue, formData, idcliente, idclientePotencial) {
    const resp = await Vue.$api.call(
      'estudioFinanciero.insert',
      {
        values: {
          idcliente,
          idcliente_potencial: idclientePotencial,
          idempleado: formData.idempleado,
          idtestudio_financiero: formData.idtestudio_financiero,
          fsolicitud: formData.fsolicitud,
          fvalidez: formData.fvalidez,
          importe_limite: formData.importe_limite,
          observaciones: formData.observaciones,
          resultado: formData.resultado,
          idusuario: formData.idusuario,
          estado: 1,
        },
      },
    )
    return resp.data.result.dataset[0]
  },
}